<template>
  <dl class="flex flex-wrap gap-3 mb-3 md:flex-nowrap ">
    <div class="flex justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate">{{ $t("device.header.serialNumber") }}</dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-if="device">{{ device.serialNumber }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>

    <div class="flex justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate">{{ $t("device.header.id") }}</dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base">{{ getDeviceId(device) ? getDeviceId(device) : "-" }} </dd>
    </div>

    <div class="flex justify-between w-full px-4 py-1 overflow-hidden rounded-lg shadow-md md:py-2 overflow state_unknown">
      <dt class="my-auto text-sm truncate">{{ $t("device.header.firmware") }} </dt>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-if="device.firmware && device.firmware.version">{{ device.firmware.version }} </dd>
      <dd class="my-auto text-sm font-medium lg:font-semibold lg:text-base" v-else>-</dd>
    </div>
  </dl>

  <div class="relative ">
    <dl class="relative top-0 flex flex-wrap justify-between w-full gap-3 lg:flex-nowrap">

      <!-- network -->
      <div ref='tooltipTargetHighestNetwork' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        {{ getHighestDescription("network", device) }}
      </div>
      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto justify-between relative h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-fit order-1 2xl:order-none',
        ...onlineStatusColor(device, 'network')
      ]">
        <div data-accordion="open">
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent" id="accordion-open-heading-network" data-accordion-target="#accordion-open-body-network" aria-expanded="false" aria-controls="accordion-open-body-network">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("device.header.connectivityStatus") }}</dt>

                <div v-if="hasNetworkAlert" class="text-sm font-medium lg:font-semibold">
                  <p ref='tooltipTriggerHighestNetwork' class="flex" v-html="getMostCriticalAlert('network', device)"></p>
                </div>

                <div v-else class="text-sm font-medium lg:font-semibold ">
                  <span v-if="device.isOnline">
                    Online via 4G/LTE {{ getConnectionStatus(device) }}
                  </span>
                  <span v-else>{{ presenceStatusLabel(device) }}</span>
                </div>

              </div>
              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasNetworkAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <DeviceMobileIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>

            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>

          <div id="accordion-open-body-network" class="hidden pb-0" aria-labelledby="accordion-open-heading-network">
            <p ref='tooltipTriggerModemLink' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'modem_link', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.modem_link") }}</span>
            </p>
            <p ref='tooltipTargetModemLink' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("network", "modem_link", device) }}
            </p>

            <p ref='tooltipTriggerModemConQuality' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'modem_connection_quality', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.modem_connection_quality") }}</span>
            </p>
            <p ref='tooltipTargetModemConQuality' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("network", "modem_connection_quality", device) }}
            </p>

            <p ref='tooltipTriggerNetCloudRep' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('network', 'network_cloud_reported', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.network_cloud_reported") }}</span>
            </p>
            <p ref='tooltipTargetNetCloudRep' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("network", "network_cloud_reported", device) }}
            </p>

          </div>
        </div>
      </div>


      <!-- WIFI -->
      <div ref='tooltipTargetHighestWifi' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        {{ getHighestDescription("wifi", device) }}
      </div>
      <div v-show="isConnectedWifi" :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-fit order-1 2xl:order-none',
        ...wifiStatusColor(device, 'wifi')]">

        <div data-accordion="open">
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent" id="accordion-open-heading-power" data-accordion-target="#accordion-open-body-wifi" aria-expanded="false" aria-controls="accordion-open-body-wifi">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("stateSummary.label.wifiConnection") }}</dt>

                <div v-if="hasWifiAlert" class="text-sm font-medium lg:font-semibold">
                  <p ref='tooltipTriggerHighestWifi' class="flex" v-html="getMostCriticalAlert('wifi', device)"></p>
                </div>
                <div v-else class="text-sm font-medium lg:font-semibold">
                  {{ wifiStatusText(device) }}
                </div>
              </div>
              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasWifiAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <WifiIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>

            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>

          <div id="accordion-open-body-wifi" class="hidden pb-0" aria-labelledby="accordion-open-heading-wifi">
            <p class="mb-1"><br></p>
            <p ref='tooltipTriggerWifiLink' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('wifi', 'wifi_link', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.wifi_link") }}</span>
            </p>
            <p ref='tooltipTargetWifiLink' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("wifi", "wifi_link", device) }}
            </p>

            <p ref='tooltipTriggerWifiConnectionQuality' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('wifi', 'wifi_connection_quality', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.wifi_connection_quality") }}</span>
            </p>
            <p ref='tooltipTargetWifiConnectionQuality' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("wifi", "wifi_connection_quality", device) }}
            </p>
          </div>
        </div>
      </div>



      <!-- Power status -->
      <div ref='tooltipTargetHighestPower' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        {{ getHighestDescription("power", device) }}
      </div>
      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-fit order-1 2xl:order-none',
        ...powerSupplyStatusColor(device, 'power')]">
        <div data-accordion="open">

          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent " id="accordion-open-heading-power" data-accordion-target="#accordion-open-body-power" aria-expanded="false" aria-controls="accordion-open-body-power">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("stateSummary.label.powerSupplyState") }}</dt>

                <div v-if="hasPowerAlert" class="text-sm font-medium lg:font-semibold ">
                  <p ref='tooltipTriggerHighestPower' class="flex" v-html="getMostCriticalAlert('power', device)"></p>
                </div>
                <div v-else class="text-sm font-medium lg:font-semibold ">
                  {{ powerSupplyStatusText(device, $t) }}
                </div>
              </div>

              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasPowerAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <BatteryIcon v-else class="w-8 h-8 px-1 mx-1 rounded-full" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-power" class="hidden pb-0" aria-labelledby="accordion-open-heading-power">
            <p class="mb-1"><br></p>
            <p ref='tooltipTriggerPowerSupply' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('power', 'power_supply', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.power_supply") }}</span>
            </p>
            <p ref='tooltipTargetPowerSupply' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("power", "power_supply", device) }}
            </p>

            <p ref='tooltipTriggerBatteryFault' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('power', 'battery_fault', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.battery_fault") }}</span>
            </p>
            <p ref='tooltipTargetBatteryFault' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("power", "battery_fault", device) }}
            </p>
          </div>
        </div>
      </div>



      <!-- Accessory status -->
      <div ref='tooltipTargetHighestAccessories' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
        {{ getHighestDescription("accessories", device) }}
      </div>
      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-fit order-1 2xl:order-none',
        ...braceletPresenceStatusColor(device, 'accessories')
      ]
        ">
        <div data-accordion="open">
          <!-- accessories -->
          <div class="flex flex-wrap justify-between text-gray-800 bg-transparent " id="accordion-open-heading-accessory" data-accordion-target="#accordion-open-body-accessory" aria-expanded="false" aria-controls="accordion-open-body-accessory">
            <div class="flex justify-between w-full">
              <div class="justify-between block">
                <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("stateSummary.label.braceletPresenceState") }}</dt>

                <div v-if="hasAccessoryAlert" class="text-sm font-medium lg:font-semibold ">
                  <p ref="tooltipTriggerHighestAccessories" class="flex" v-html="getMostCriticalAlert('accessories', device)"></p>
                </div>
                <div v-else class="text-sm font-medium lg:font-semibold"> {{ braceletPresenceStatusText(device, $t) }} </div>

              </div>
              <div class="flex items-center flex-shrink-0 lg:hidden 2xl:flex">
                <BellIcon v-if="hasAccessoryAlert" class="w-8 h-8 px-1 mx-1 rounded-full stroke-red-800" aria-hidden="true" />
                <BraceletIcon v-else :class="['h-8', 'w-8', 'rounded-full', 'px-1', 'mx-1']" aria-hidden="true" />
              </div>
            </div>
            <div class="flex justify-center flex-1 w-full mt-0 text-gray-400 rotate-180 2xl:mt-2">
              <svg data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </div>
          </div>
          <div id="accordion-open-body-accessory" class="hidden pb-0" aria-labelledby="accordion-open-heading-accessory">
            <p class="mb-1"><br></p>
            <p ref='tooltipTriggerBraceletPresence' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('accessories', 'bracelet_presence', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.bracelet_presence") }}</span>
            </p>
            <p ref='tooltipTargetBraceletPresence' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("accessories", "bracelet_presence", device) }}
            </p>

            <p ref='tooltipTriggerBraceletBattery' class='flex mb-1'>
              <span class='w-6 h-6 mr-2 font-medium lg:w-4 lg:h-4 2xl:w-6' v-html="getIconOf('accessories', 'bracelet_battery', device)"></span>
              <span class='flex items-center text-sm font-light hover:font-semibold hover:cursor-default lg:text-xs 2xl:text-sm'>{{ $t("alarms.bracelet_battery") }}</span>
            </p>
            <p ref='tooltipTargetBraceletBattery' class="inline-block absolute invisible z-50 py-2.5 px-3 text-sm font-medium text-white bg-gray-800 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip">
              {{ getDescription("accessories", "bracelet_battery", device) }}
            </p>
          </div>
        </div>
      </div>


      <div :class="[
        'rounded-lg overflow overflow-hidden shadow-md px-4 py-2 flex-auto justify-between relative h-full cursor-pointer w-full 2xl:flex-auto 2xl:w-fit order-1 2xl:order-none',
        ...dailyCheckInStatusColor(device),
      ]">
        <div class="flex justify-between ">
          <div class="justify-between block w-full sm:flex md:block">
            <dt class="text-sm truncate lg:text-xs 2xl:text-sm">{{ $t("device.general.dailyCheckIn") }}</dt>
            <dd class="text-sm font-medium lg:font-semibold lg:text-xs 2xl:text-sm"> {{ dailyCheckInStatusText(device, $t) }} </dd>
          </div>
          <div class="items-center flex-shrink-0 hidden md:flex">
            <CalendarIcon :class="['h-8', 'w-8', 'rounded-full', ...dailyCheckInStatusColor(device), 'px-1']" aria-hidden="true" />
          </div>
        </div>
      </div>
    </dl>

  </div>
</template>

<script>
import {
  powerSupplyStatusColor,
  braceletBatteryStatusColor,
  braceletPresenceStatusColor,
  onlineStatusColor,
  modemStatusColor,
  cloudPresenceStatusColor,
  devicePresenceStatusColor,
  dailyCheckInStatusColor,
  overallBraceletStatusColor,
  wifiStatusColor,
  batteryStatusColor,
  deviceIdentityStatusColor,

} from "@/lib/statusColors";
import { hasAlert, getMostCriticalAlert, hasWifiConnection, getIcon, getIconOf, getDescription, getHighestDescription } from "@/lib/alarms";
import { CalendarIcon, WifiIcon, DeviceMobileIcon, BellIcon } from "@heroicons/vue/outline";
import { BraceletIcon } from "@/components/icons";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { BatteryIcon } from "./icons";
import { useI18n } from "vue-i18n";
import { braceletPresenceStatusText, wifiStatusText, dailyCheckInStatusText, powerSupplyStatusText } from "@/lib/statusTexts";
import { Tooltip } from "flowbite";

export default {
  name: "DeviceStateSummary^",
  methods: {
    braceletPresenceStatusText,
    powerSupplyStatusText,
    onlineStatusColor,
    dailyCheckInStatusColor,
    modemStatusColor,
    overallBraceletStatusColor,
    wifiStatusColor,
    batteryStatusColor,
    deviceIdentityStatusColor,
    dailyCheckInStatusText,
    powerSupplyStatusColor,
    braceletBatteryStatusColor,
    braceletPresenceStatusColor,
    cloudPresenceStatusColor,
    devicePresenceStatusColor,
    hasAlert,
    getMostCriticalAlert,
    hasWifiConnection,
    wifiStatusText,
    getIcon,
    getIconOf,
    getDescription,
    getHighestDescription
  },
  components: {
    CalendarIcon,
    BraceletIcon,
    BatteryIcon,
    WifiIcon,
    BellIcon,
    DeviceMobileIcon
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const device = computed(() => store.state.device.current);

    const tooltipTriggerModemLink = ref(null);
    const tooltipTargetModemLink = ref(null);

    const tooltipTriggerModemConQuality = ref(null);
    const tooltipTargetModemConQuality = ref(null);

    const tooltipTriggerNetCloudRep = ref(null);
    const tooltipTargetNetCloudRep = ref(null);

    const tooltipTriggerWifiLink = ref(null);
    const tooltipTargetWifiLink = ref(null);

    const tooltipTriggerWifiConnectionQuality = ref(null);
    const tooltipTargetWifiConnectionQuality = ref(null);

    const tooltipTriggerPowerSupply = ref(null);
    const tooltipTargetPowerSupply = ref(null);

    const tooltipTriggerBatteryFault = ref(null);
    const tooltipTargetBatteryFault = ref(null);

    const tooltipTriggerBraceletPresence = ref(null);
    const tooltipTargetBraceletPresence = ref(null);

    const tooltipTriggerBraceletBattery = ref(null);
    const tooltipTargetBraceletBattery = ref(null);

    const tooltipTriggerHighestNetwork = ref(null);
    const tooltipTargetHighestNetwork = ref(null);

    const tooltipTriggerHighestWifi = ref(null);
    const tooltipTargetHighestWifi = ref(null);

    const tooltipTriggerHighestPower = ref(null);
    const tooltipTargetHighestPower = ref(null);

    const tooltipTriggerHighestAccessories = ref(null);
    const tooltipTargetHighestAccessories = ref(null);

    onMounted(() => {
      const options = {
        placement: 'bottom',
        triggerType: 'hover',
      }
      new Tooltip(tooltipTargetModemLink.value, tooltipTriggerModemLink.value, options);
      new Tooltip(tooltipTargetModemConQuality.value, tooltipTriggerModemConQuality.value, options);
      new Tooltip(tooltipTargetNetCloudRep.value, tooltipTriggerNetCloudRep.value, options);
      new Tooltip(tooltipTargetWifiLink.value, tooltipTriggerWifiLink.value, options);
      new Tooltip(tooltipTargetWifiConnectionQuality.value, tooltipTriggerWifiConnectionQuality.value, options);
      new Tooltip(tooltipTargetPowerSupply.value, tooltipTriggerPowerSupply.value, options);
      new Tooltip(tooltipTargetBatteryFault.value, tooltipTriggerBatteryFault.value, options);
      new Tooltip(tooltipTargetBraceletPresence.value, tooltipTriggerBraceletPresence.value, options);
      new Tooltip(tooltipTargetBraceletBattery.value, tooltipTriggerBraceletBattery.value, options);
      new Tooltip(tooltipTargetHighestNetwork.value, tooltipTriggerHighestNetwork.value, options);
      new Tooltip(tooltipTargetHighestWifi.value, tooltipTriggerHighestWifi.value, options);
      new Tooltip(tooltipTargetHighestPower.value, tooltipTriggerHighestPower.value, options);
      new Tooltip(tooltipTargetHighestAccessories.value, tooltipTriggerHighestAccessories.value, options);
    })

    const hasNetworkAlert = computed(() => hasAlert('network', device.value))
    const hasPowerAlert = computed(() => hasAlert('power', device.value))
    const hasAccessoryAlert = computed(() => hasAlert('accessories', device.value))
    const hasWifiAlert = computed(() => hasAlert("wifi", device.value))
    const isConnectedWifi = computed(() => hasWifiConnection(device.value))

    const presenceStatusLabel = (device) => {
      if (device.isOnline) {
        return t("device.errorStatus.ok");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_battery_empty") {
        return t("device.errorStatus.emptyBattery");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_low_power_mode") {
        return t("device.errorStatus.lowPowerMode");
      }
      if (device.stateSummary.deviceReportedDevicePresence === "offline_turned_off") {
        return t("device.errorStatus.off");
      }
      return t("device.errorStatus.missingDevice");
    };

    const getDeviceId = (device) => {
      return device.configV2.alarm_app.destinations.filter(destination => destination.scaip).map(destination => destination.scaip.controller_id).join(", ")
    };

    const getConnectionStatus = (device) => {
      let ret;
      if (device.telemetry && device.telemetry.modem) {
        ret = device.telemetry.modem.operator + "(" + device.telemetry.modem.technology + ")";
      }
      if (ret.length > 10) {
        ret = ret.substring(0, 10) + "...";
      }

      return ret;
    };

    const snakeToCamel = (str) =>
      str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));


    return {
      device,
      presenceStatusLabel,
      snakeToCamel,
      getDeviceId,
      getConnectionStatus,
      hasNetworkAlert,
      hasPowerAlert,
      hasAccessoryAlert,
      isConnectedWifi,
      hasWifiAlert,


      tooltipTargetModemLink,
      tooltipTriggerModemLink,

      tooltipTriggerModemConQuality,
      tooltipTargetModemConQuality,

      tooltipTriggerNetCloudRep,
      tooltipTargetNetCloudRep,

      tooltipTargetWifiLink,
      tooltipTriggerWifiLink,

      tooltipTargetWifiConnectionQuality,
      tooltipTriggerWifiConnectionQuality,

      tooltipTriggerPowerSupply,
      tooltipTargetPowerSupply,

      tooltipTriggerBatteryFault,
      tooltipTargetBatteryFault,

      tooltipTriggerBraceletPresence,
      tooltipTargetBraceletPresence,

      tooltipTriggerBraceletBattery,
      tooltipTargetBraceletBattery,

      tooltipTriggerHighestNetwork,
      tooltipTargetHighestNetwork,

      tooltipTriggerHighestWifi,
      tooltipTargetHighestWifi,

      tooltipTriggerHighestPower,
      tooltipTargetHighestPower,

      tooltipTriggerHighestAccessories,
      tooltipTargetHighestAccessories,
    };
  },
};
</script>
